<template>
  <div>
    <movementmenuVue/>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="กำหนดการปฏิทินการย้าย ข้าราชการครู"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="12" class="text-right">
              <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                <v-timeline-item color="grey lighten-1" fill-dot left small>
                  <v-card>
                    <v-card-title class="grey lighten-1 justify-end">
                      <h2 class="mr-4 white--text font-weight-light">
                        กำหนดการ <span class="red--text">ข้าราชการครู</span> 
                      </h2>
                      <v-icon dark size="42">
                        mdi-calendar
                      </v-icon>
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                   <Period/>
                        </v-col>                       
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item color="purple lighten-2" fill-dot right>
                  <v-card>
                    <v-card-title class="purple lighten-2">
                      <v-icon dark size="42" class="mr-4">
                        mdi-calendar
                      </v-icon>
                      <h2 class="white--text font-weight-light">
                      วิทยาลัย แจ้งเงือนไขสาขาวิชา
                      </h2>
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                             <periodCollege/> 
                        </v-col>                     
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item color="purple lighten-2" fill-dot right>
                  <v-card>
                    <v-card-title class="purple lighten-2">
                      <v-icon dark size="42" class="mr-4">
                        mdi-calendar
                      </v-icon>
                      <h2 class="white--text font-weight-light">
                       วิทยาลัย รวบรวมคำร้องขอย้ายครู
                      </h2>
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                            <periodCollegeRequest/>
                        </v-col>                     
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>


                <v-timeline-item color="info lighten-1" fill-dot left small>
                  <v-card>
                    <v-card-title class="info lighten-1 justify-end">
                      <h2 class="mr-4 white--text font-weight-light">
                        สอจ. พิจารณาย้ายครู (ย้ายออก)
                      </h2>
                      <v-icon dark size="42">
                        mdi-calendar
                      </v-icon>
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                         <periodVecprovinces/>
                        </v-col>                       
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>


                <v-timeline-item color="purple lighten-2" fill-dot right>
                  <v-card>
                    <v-card-title class="purple lighten-2">
                      <v-icon dark size="42" class="mr-4">
                        mdi-calendar
                      </v-icon>
                      <h2 class="white--text font-weight-light">
                       วิทยาลัยปลายทาง (รับฟังความคิดเห็น) 
                      </h2>
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                         <periodCollegediscussTeach/>
                        </v-col>                     
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>



                <v-timeline-item color="purple lighten-2" fill-dot right>
                  <v-card>
                    <v-card-title class="purple lighten-2">
                      <v-icon dark size="42" class="mr-4">
                        mdi-calendar
                      </v-icon>
                      <h2 class="white--text font-weight-light">
                       วิทยาลัยปลายทาง (รับรายงานตัว) 
                      </h2>
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                     <PeriodCollegeTeachReport/>
                        </v-col>                     
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>


          
              </v-timeline>
            </v-col>
          </v-row>
        </v-card>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import Period from '../../../components/admin/period.vue';
import periodCollege from '../../../components/admin/periodCollege.vue';
import periodCollegeRequest from '../../../components/admin/periodCollegeRequest.vue';
import periodVecprovinces from '../../../components/admin/periodVecprovinces.vue';
import periodCollegediscussTeach from '../../../components/admin/periodCollegediscussTeach.vue';
import PeriodCollegeTeachReport from '../../../components/admin/periodCollegeTeachReport.vue';
import movementmenuVue from '../../../components/admin/movementmenu.vue';


export default {
    name: "HRvecTransferTeachers",
    data() {
        return {
            loading: true,
            ApiKey: "HRvec2021",
        };
    },
    mounted() { },
    methods: {},
    components: { Period, periodCollege, periodCollegeRequest, periodVecprovinces, periodCollegediscussTeach, PeriodCollegeTeachReport,movementmenuVue }
};
</script>

<style lang="scss" scoped></style>
